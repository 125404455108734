import { getRequest, postRequest, putRequest, deleteRequest } from '../common/utils'

export function getByCriteria(criteria) {
    return getRequest(`/shop`, criteria)
}

export function getSummaryByCriteria(criteria) {
    return getRequest(`/shopSummary`, criteria)
}

export function getById(id, isIncludeAsset) {
    return getRequest(`/shop/${id}`, { isIncludeAsset })
}

export function getShopAssetById(id) {
    return getRequest(`/shop-asset/${id}`)
}

export function getShopMeterById(id) {
    return getRequest(`/shop-meter/${id}`)
}

export function add(data) {
    return postRequest(`/shop`, data)
}

export function update(data, id) {
    return putRequest(`/shop/${id}`, data)
}

export function getFilterData() {
    return getRequest(`/shopmodel-filterdata`)
}

export function getShopCountByCriteria(criteria) {
    return getRequest(`/shopcount`, criteria)
}

export function deleteShop(id) {
    return deleteRequest(`/shop/${id}`)
}

export function getUnassignAssetFromGateway(id) {
    return getRequest(`/shop-unassign-asset-gateway/${id}`)
}

export function getShopFilterData() {
    return getRequest(`/shop-filter-data`)
}
